import * as React from 'react';
import classnames from 'clsx';
import { useAsyncRef } from '../../../../providers/useAsyncRef';
import { classes } from '../style/VideoPlayer.st.css';
import { useDidMount } from '../../../../providers/useDidMount';
import { useChangedEffect } from '../../../../providers/useChangedEffect';
import { TestIds } from '../constants';
import { getSDK, vimeoSDK } from '../../../../providers/ScriptLoader';
import {
  IPlayer,
  IVimeoPlayer,
  IPlayerProps,
  IPlayerHandles,
  VimeoEvent,
} from './players.types';

const EVENTS = {
  PLAY: 'play' as VimeoEvent,
  PAUSE: 'pause' as VimeoEvent,
  END: 'ended' as VimeoEvent,
  VOLUME_CHANGE: 'volumechange' as VimeoEvent,
  PROGRESS: 'timeupdate' as VimeoEvent,
  ERROR: 'error' as VimeoEvent,
};
const usePlayer = (
  container: React.MutableRefObject<HTMLDivElement | null>,
  config: any,
): (() => Promise<IVimeoPlayer>) => {
  const [waitForPlayer, , setPlayer] = useAsyncRef<IVimeoPlayer>();

  useDidMount(() => {
    const waitForSDK = getSDK<IVimeoPlayer>(vimeoSDK);
    waitForSDK
      .then((Vimeo: any) => {
        setPlayer(new Vimeo.Player(container.current, config) as IVimeoPlayer);
      })
      .catch(e => {
        // TODO - handle errors properly
        throw e;
      });

    return () => {
      waitForPlayer()
        .then(player => player.destroy())
        .catch(e => {
          // TODO - handle errors properly
          throw e;
        });
    };
  });

  return waitForPlayer;
};

function subscribeToPlayerEvents(
  player: IVimeoPlayer,
  {
    onProgress,
    onPlay,
    onPause,
    onEnded,
    onFirstPlay,
    onFirstEnded,
    onError,
  }: Partial<IPlayerProps>,
  currentTimeRef: React.MutableRefObject<number>,
  volumeRef: React.MutableRefObject<number>,
  isPlayingNow: React.MutableRefObject<boolean>,
  firstPlayEnded: React.MutableRefObject<boolean>,
  firstPlayStarted: React.MutableRefObject<boolean>,
) {
  player.on(EVENTS.PLAY, () => {
    if (!firstPlayStarted.current) {
      firstPlayStarted.current = true;
      onFirstPlay?.();
    }

    isPlayingNow.current = true;
    onPlay?.();
  });

  player.on(EVENTS.PAUSE, () => {
    isPlayingNow.current = false;
    onPause?.();
  });

  player.on(EVENTS.END, () => {
    if (!firstPlayEnded.current) {
      firstPlayEnded.current = true;
      onFirstEnded?.();
    }

    isPlayingNow.current = false;
    onEnded?.();
  });

  player.on(EVENTS.VOLUME_CHANGE, ({ volume: value }: { volume: number }) => {
    volumeRef.current = value * 100;
  });

  player.on(EVENTS.PROGRESS, ({ seconds }: { seconds: number }) => {
    currentTimeRef.current = seconds;
    onProgress?.(seconds);
  });

  if (onError) {
    player.on('error', onError);
  }
}

const useChangedPropsEffects = (
  { src, playing, muted, volume }: Partial<IPlayerProps>,
  firstPlayStarted: React.MutableRefObject<boolean>,
  firstPlayEnded: React.MutableRefObject<boolean>,
  waitForPlayer: () => Promise<IVimeoPlayer>,
) => {
  useChangedEffect(src, () => {
    firstPlayStarted.current = false;
    firstPlayEnded.current = false;
  });
  useChangedEffect(playing, () =>
    waitForPlayer().then(player => (playing ? player.play() : player.pause())),
  );
  useChangedEffect(muted, () =>
    waitForPlayer().then(player => player.setVolume(muted ? 0 : 1)),
  );
  useChangedEffect(volume, () =>
    waitForPlayer().then(player => player.setVolume(volume! / 100)),
  );
};

const getHandles = (
  waitForPlayer: () => Promise<IVimeoPlayer>,
  isPlayingNow: React.MutableRefObject<boolean>,
  durationRef: React.MutableRefObject<number | string>,
  currentTimeRef: React.MutableRefObject<number>,
  volumeRef: React.MutableRefObject<number>,
): IPlayerHandles => {
  const handles: IPlayerHandles = {
    play: () => waitForPlayer().then(player => player.play()),
    pause: () => waitForPlayer().then(player => player.pause()),
    togglePlay: () => (isPlayingNow.current ? handles.pause() : handles.play()),
    getDuration: () => durationRef.current,
    getCurrentTime: () => currentTimeRef.current,
    seekTo: time => waitForPlayer().then(player => player.setCurrentTime(time)),
    getVolume: () => volumeRef.current,
    setVolume: fraction =>
      waitForPlayer().then(player => player.setVolume(fraction / 100)),
    isMuted: () => volumeRef.current === 0,
    isPlaying: () => isPlayingNow.current,
    mute: () => waitForPlayer().then(player => player.setVolume(0)),
    unMute: () => waitForPlayer().then(player => player.setVolume(1)),
  };

  return handles;
};

const Player: IPlayer = (props, ref) => {
  const {
    src,
    playing,
    muted,
    loop,
    showTitle,
    volume = 0,
    onReady,
    onInit,
    onDuration,
    onProgress,
    onPlay,
    onPause,
    onEnded,
    onFirstPlay,
    onFirstEnded,
    onError,
  } = props;
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const durationRef = React.useRef<string | number>(0);
  const currentTimeRef = React.useRef<number>(0);
  const volumeRef = React.useRef<number>(volume);

  const isPlayingNow = React.useRef<boolean>(false);
  const firstPlayStarted = React.useRef<boolean>(false);
  const firstPlayEnded = React.useRef<boolean>(false);

  const waitForPlayer = usePlayer(containerRef, {
    url: src,
    autoplay: playing,
    muted,
    loop,
    title: showTitle,
  });

  useDidMount(() => {
    waitForPlayer()
      .then(player => {
        player
          .ready()
          .then(() => {
            onReady?.();

            player
              .getDuration()
              .then((value: number) => {
                durationRef.current = value;
                onDuration?.(value);
              })
              .catch(e => {
                // TODO - handle errors properly
                throw e;
              });
          })
          .catch(e => {
            // TODO - handle errors properly
            throw e;
          });

        subscribeToPlayerEvents(
          player,
          {
            onProgress,
            onPlay,
            onPause,
            onEnded,
            onFirstPlay,
            onFirstEnded,
            onError,
          },
          currentTimeRef,
          volumeRef,
          isPlayingNow,
          firstPlayEnded,
          firstPlayStarted,
        );

        onInit?.(player, 'vimeo');
      })
      .catch(e => {
        // TODO - handle errors properly
        throw e;
      });
  });

  useChangedPropsEffects(
    { src, playing, muted, volume },
    firstPlayStarted,
    firstPlayEnded,
    waitForPlayer,
  );

  React.useImperativeHandle(ref, () =>
    getHandles(
      waitForPlayer,
      isPlayingNow,
      durationRef,
      currentTimeRef,
      volumeRef,
    ),
  );

  return (
    <div
      ref={containerRef}
      data-player-name="Vimeo"
      data-testid={TestIds.vimeo}
      className={classnames(classes.playerContainer, classes.vimeoContainer)}
    />
  );
};

export default React.forwardRef(Player);
